
export default {
  name: 'PageProgress',
  props: {
    value: {
      type: Number,
      default: 0
    }
  }
}
